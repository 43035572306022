// body {
//     font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;    
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }
// body {
//     font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;    
//     -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }
body {
    font-family: "SegoeUI";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'SegoeUI';
    src: url('/assets/fonts/SegoeUI.ttf') format("truetype");
}
@font-face {
    font-family: 'SegoeUIBold';
    src: url('/assets/fonts/SegoeUIBlack.ttf') format("truetype");
}
// @font-face {
//     font-family: "SegoeBold";    
//     src:  local('SegeoNormal'),url(/assets/fonts/Segoe/UI/Bold.ttf);
// }
// @font-face {
//     font-family: "Segoe UI";    
//     src: local('Segoe UI'),url("/assets/fonts/SEGOEUI.TTF");
// }

html {
    scroll-behavior: smooth;
}
.has-text-weight-bold{
    font-family: 'SegoeUIBold';
}
.fontWeightGiant {
    font-weight: 900;
    font-family: 'SegoeUIBold';

}
.post{
    line-height: 25px !important;
}

.hero.is-small .hero-body {
    padding: 2.5rem;
}

.has-background-transparent {
    background-color: transparent!important;
}

.navbar-item {
  transition: .2s ease;  
}

.navbar-item:hover {
    // font-weight: 700;
    transition: .2s ease;
    -webkit-text-stroke: .7px black;
}

.navbar-item.is-active:hover {
    // font-weight: 700;
    transition: .2s ease;
    -webkit-text-stroke: unset;
}

span.circle {
    background: $primary;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    color: white;
    display: inline-block;
    font-weight: bold;
    line-height: 28px;
    /* margin-right: 5px; */
    text-align: center;
    width: 28px;
    font-weight: 900;
  }
  .has-margin{
    margin: auto;
  }
 .recaptcha-text{
    max-width: 300px !important;
    margin: auto;
 }
  .name::placeholder, .email::placeholder, .message::placeholder, .phone::placeholder { /* Microsoft Edge */
    color: white;
    font-weight: 300;
    font-size: 16px !important;
    opacity: 80% !important;

   }
   .contactus{
    // text-decoration: underline 0.4rem solid #00001E !important;
    // -webkit-text-decoration-line:  underline !important;
    // -webkit-text-decoration-color: #00001E !important;
    // -webkit-text-decoration-style: solid !important;
    // -webkit-text-decoration-thickness: 6.4px !important;   
    border-bottom: 0.4rem solid #00001E !important;
}
.name, .email, .message, .phone{
    border: unset;
    background-color: unset;
    border-bottom: 2px solid #fefefe;
    border-radius: unset !important;
    color:#fff !important;
    width: 100%;
    padding: 5px 0px;
}

button,
textarea,
input,
select,
a{
 -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 -webkit-tap-highlight-color: transparent;
 -webkit-user-select: text;
 -khtml-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
  user-select: none;

}

.has-text-weight-900 {
    font-weight: 900!important;
    font-family: 'SegoeUIBold';

}

html {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.scrollDown img {
    animation: vertical-shaking 3.4s infinite;
}

@keyframes vertical-shaking {
    0% { transform: translateY(-7px) }
    25% { transform: translateY(7px) }
    50% { transform: translateY(-7px) }
    75% { transform: translateY(7px) }
    100% { transform: translateY(-7px) }
   
}

.typeTitle{
    margin-bottom: 2.3rem !important;
}
#scrollToTop {
    display: flex;
    gap: 2em;
    z-index: 2;
    position: fixed;
    bottom: 24px;
    right: 20px;
    width: 32px;
    height: 32px;
    text-align: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.5s;
}

.contact-title{
    max-width: 550px !important;
    margin: auto;
    margin-bottom: 48px !important;
}
.white-special-underline{
    text-decoration:underline 0.4rem solid #00001E !important;
}

.see-button{
    // bottom: 70px;
    margin: 24px 0;
    background-color: #00D5FF !important;
    color: #00001E !important;
}
.see-button:hover{
    // bottom: 70px;
    background-color: #FFF !important;
    color:#00001E !important;
}
@media screen and (min-width: 1600px) {
    .container:not(.is-max-desktop):not(.is-max-widescreen) {
        max-width: 1490px;
    }
}

@media screen and (min-width: 1408px) {
    .has-padding-x-10-fullhd {
        padding-left: 7rem!important;
        padding-right: 7rem!important;
    }
}

@media (min-width: 769px) and (max-width: 900px), print {
    .hero-body {
        padding: 3rem 1rem;
    }
    .hero.is-medium .hero-body {
        padding: 9rem 1.5rem;
    }
}



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .mobileview-px{
        padding-left: unset !important;
        padding-right: unset !important;
    }
    .partner{
        margin-top: 40px !important;
    }
    .camel img{
        max-width: 65% !important;
        width:100%;
    }
    h1 {
        font-size: 55px;
        line-height: 55px;
    }
    h2,
    h2 a{
        font-size: 35px;
        line-height: 35px;
    }
    h2 .white-special-border{
        border-bottom: 6px solid white;
    }
    h2 .primary-special-border{
        border-bottom: 6px solid $primary;
    }
    h3 {
        font-size:25px;
        line-height: 2rem;
    }
    h3 .primary-special-underline{
        text-decoration: underline .2rem solid $primary;
    }
    h3 .primary-special-border{
        border-bottom: .2rem solid $primary;
    }
    h3 .white-special-underline{
        text-decoration: underline .2rem solid white;
    }
    h3 .dark-special-border {
        border-bottom: 3px solid $dark;
    }
    h4 {
        font-size: 18px;
        line-height: 1rem;
    }
    h4 .dark-special-border {
        border-bottom: 3px solid $dark;
    }
    h4 .primary-special-border {
        border-bottom: 3px solid $primary;
    }
    h5 {
        font-size: .7rem;
        line-height: 1.5rem;
    }
    .projectName {
        font-size: 40px;
        line-height: 54px;
    }
    h6.projectDescription {
        line-height: 34px;
        font-size: 18px;
    }
    h6.projectType {
        font-size: 1rem;
    }
    
    .jobDescription ul li
    .landingPage {
        .mainTitleColumns {
            min-height: unset;

            img {
                max-width: 165px;
            }
            
        }
        .mainTitle {
            display: flex;
            flex-direction: row;
        
            .border {
                border-left: 8px solid black;
                margin: 11px 15px 60px 0;
            }
        }
    }
    .centeredUnderscore-dark {
        width: 19%;
        border-bottom: 3px solid $dark;
    }
}

/* Extra small devices (phones, 320px and down) */
@media screen and (max-width:381px){
    .projectName {
        font-size: 32px;
        line-height: 44px;
    }
}
@media only screen and (max-width: 320px) {
    .projectName {
        font-size: 32px;
        line-height: 44px;
    }
    h6.projectDescription {
        line-height: 34px;
        font-size: 18px;
    }
    h6.projectType {
        font-size: 1rem;
    }

}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 770px) {
    .projectSubtitle{
        margin-top: 20px;
    }
}
@media only screen and (min-width: 600px) {
    h1 {
        font-size: 82px;
        line-height: 88px;
    }
    h2,
    h2 a{
        font-size: 35px;
        line-height: 35px;
    }
    h2 .white-special-border{
        border-bottom: 6px solid white;
    }
    h2 .primary-special-border{
        border-bottom: 6px solid $primary;
    }
    h3 {
        font-size: 2rem;
        line-height: 2.9rem;
    }
    h3 .primary-special-underline{
        text-decoration: underline .3rem solid $primary;
    }
    h3 .primary-special-border{
        border-bottom: .3rem solid $primary;
    }    
    h3 .white-special-underline{
        text-decoration: underline .3rem solid white;
    }
    h3 .dark-special-border {
        border-bottom: 4px solid $dark;
    }
    h4 {
        font-size: 1.1rem;
        line-height: 1.2rem;
    }
    h4 .dark-special-border {
        border-bottom: 3px solid $dark;
    }
    h4 .primary-special-border {
        border-bottom: 3px solid $primary;
    }
    .projectName {
        font-size: 32px;
        line-height: 40px;
    }
   
    h6.projectDescription {
        line-height: 34px;
        font-size: 18px;
    }
    h6.projectType {
        font-size: 1rem;
    }
    .landingPage {
        .mainTitle {
            .border {
                border-left: 13px solid black;
                margin: 22px 18px 60px 0;
            }
        }
    }
    .centeredUnderscore-dark {
        width: 14%;
        border-bottom: 5px solid $dark;
    }
}

h3.typeTitle {
    font-size: 32px;
    line-height: 44px;
}
h4.technologyTitle {
    font-size: 28px;
    line-height: 32px;
}
@media only screen and (max-width: 808px) {
    h3.has-text-dark{
        font-size: 29px !important;
    }
}
@media only screen and (max-width: 769px) {
    h3.has-text-dark, h3.specialSize{
        font-size: 35px !important;
    }
}
@media only screen and (max-width: 475px) {
    .vacancyContent{
        width: 15% !important;
    }
    h3.has-text-dark, h3.specialSize{
        font-size: 25px !important;
        line-height: 29px !important;
    }
    .is-three-quarters-mobile{
        width: 100% !important;
    }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .typeTitle{
        margin-bottom: 2rem !important;
    }
    h1 {
        font-size: 99px;
        line-height: 102px;
    }
    h2,
    h2 a{
        font-size: 49px;
        line-height: 49px;
    }
    h2 .white-special-border{
        border-bottom: 9px solid white;
    }
    h2 .primary-special-border{
        border-bottom: 9px solid $primary;
    }
    h3 {
        line-height: 2.6rem;
    }
    h3 .primary-special-underline{
        text-decoration: underline .2rem solid $primary;
    }
    h3 .primary-special-border{
        border-bottom: .2rem solid $primary;
    }
    h3 .white-special-underline{
        text-decoration: underline .2rem solid white;
    }
    h3 .dark-special-border {
        border-bottom: 4px solid $dark;
    }
    h3.typeTitle {
        font-size: 56px;
        line-height: 64px;
    }
    h4.technologyTitle {
        font-size: 34px;
        line-height: 40px;
    }
    h4 .dark-special-border {
        border-bottom: 3px solid $dark;
    }
    h4 .primary-special-border {
        border-bottom: 3px solid $primary;
    }
    .projectName {
        font-size: 40px;
        line-height: 64px;
    }
    h6.projectDescription {
        line-height: 34px;
        font-size: 18px;
    }
    h6.projectType {
        font-size: 1rem;
    }
    h6,
    .jobDescription ul li {
        font-size: 18px;
        line-height: 32px;
    }
    .landingPage {
        .mainTitleColumns {
            min-height: 65vh;
            img {
                max-width: unset;
            }
        }
        
        .mainTitle {
            display: flex;
            flex-direction: row;
        
            .border {
                border-left: 13px solid black;
                margin: 22px 18px 60px 0;
            }
        }
    }
    .centeredUnderscore-dark {
        width: 26%;
        border-bottom: 5px solid $dark;
    }
    // .reversedRowImageMargin {
    //     margin-top: -4.8rem;
    // }
}

/* Large devices (desktops) */
@media only screen and (min-width: 1024px) {
    h1 {
        font-size: 99px;
        line-height: 102px;
    }
    h2,
    h2 a{
        font-size: 60px;
        line-height: 60px;
    }
    h2 .white-special-border{
        border-bottom: 10px solid white;
    }
    h2 .primary-special-border{
        border-bottom: 10px solid $primary;
    }
    h3 {
        font-size: 2rem;
        line-height: 2.7rem;
    }
    h3 .primary-special-underline{
        text-decoration: underline .35rem solid $primary;
    }
    h3 .primary-special-border{
        border-bottom: .35rem solid $primary;
    }
    h3 .white-special-underline{
        text-decoration: underline .35rem solid white;
    }
    h3 .dark-special-border {
        border-bottom: 5px solid $dark;
    }
    h4 {
        font-size: 1.1rem;
        line-height: 1.3rem;
    }
    h4 .dark-special-border {
        border-bottom: 3px solid $dark;
    }
    h4 .primary-special-border {
        border-bottom: 3px solid $primary;
    }
    h6, 
    .jobDescription ul li {
        font-size: .9rem;
    }
    h6,
    .jobDescription ul li {
        font-size: 1rem;
    }
    .projectName {
        font-size: 40px;
        line-height: 64px;
    }
    h6.projectDescription {
        line-height: 34px;
        font-size: 18px;
    }
    h6.projectType {
        font-size: 1rem;
    }
    .landingPage {
        .mainTitleColumns {
            min-height: 65vh;
            img {
                max-width: unset;
            }
        }
        
        .mainTitle {
            display: flex;
            flex-direction: row;
        
            .border {
                border-left: 13px solid black;
                margin: 22px 18px 60px 0;
            }
        }
    }
    .centeredUnderscore-dark {
        width: 26%;
        border-bottom: 7px solid $dark;
    }
    // .reversedRowImageMargin {
    //     margin-top: -6.8rem;
    // }
}
.link{
    display: flex;
    align-items: center;
}
.ml-3{
    padding: 4px 6px !important;
    background-color: unset;
    border-radius: 25px;
}
.link:hover{
    .ml-3{
    padding: 4px 6px !important;
    background-color: #00001E;
    border-radius: 25px;}
}
.ml-2{
    padding: 1px 11px !important;
}
.modal{
    height:100% !important;
    position: fixed;
}
.modal-card{
    background-color:  rgba(10, 10, 10, 0.90);
    max-height:100% !important;
}
/* Extra large devices (widescreen) */
@media only screen and (min-width: 1216px) {
    h1 {
        font-size: 99px;
        line-height: 102px;
    }
    h2,
    h2 a{
        font-size: 75px;
        line-height: 75px;
    }
    h2 .white-special-border{
        border-bottom: 17px solid white;
    }
    h2 .primary-special-border{
        border-bottom: 17px solid $primary;
    }
    h3 {
        font-size: 2.5rem;
        line-height: 3.2rem;
    }
    h3 .primary-special-underline{
        text-decoration: underline .4rem solid $primary;
    }
    h3 .primary-special-border{
        border-bottom: .4rem solid $primary;
    }
    h3 .white-special-underline{
        text-decoration: underline .4rem solid white;
    }
    h3 .dark-special-border {
        border-bottom: 7px solid $dark;
    }
    
    h4 .dark-special-border {
        border-bottom: 3px solid $dark;
    }
    h4 .primary-special-border {
        border-bottom: 3px solid $primary;
    }
    h6,
    .jobDescription ul li {
        font-size: 1rem;
    }
    .projectName {
        font-size: 40px;
        line-height: 64px;
    }
    h6.projectDescription {
        line-height: 34px;
        font-size: 18px;
    }
    h6.projectType {
        font-size: 1rem;
    }
    .landingPage {
        .mainTitleColumns {
            min-height: 65vh;
            img {
                max-width: unset;
            }
        }
        
        .mainTitle {
            display: flex;
            flex-direction: row;
        
            .border {
                border-left: 13px solid black;
                margin: 22px 18px 60px 0;
            }
        }
    }
    .centeredUnderscore-dark {
        width: 26%;
        border-bottom: 7px solid $dark;
    }
    // .reversedRowImageMargin {
    //     margin-top: -7.8rem;
    // }
}

/* FullHD */
@media only screen and (min-width: 1408px) {
    h1 {
        font-size: 99px;
        line-height: 102px;
    }
    h2,
    h2 a{
        font-size: 81px;
        line-height: 81px;
    }
    h2 .white-special-border{
        border-bottom: 20px solid white;
    }
    h2 .primary-special-border{
        border-bottom: 20px solid $primary;
    }
    h3 {
        font-size: 40px;
        line-height: 3.7rem;
    }
    h3 .primary-special-underline{
        text-decoration: underline .5rem solid $primary;
    }
    h3 .primary-special-border{
        border-bottom: .5rem solid $primary;
    }
    h3 .white-special-underline{
        text-decoration: underline .5rem solid white;
    }
    h3 .dark-special-border {
        border-bottom: 8px solid $dark;
    }
    h4 {
        font-size: 1.5rem;
        line-height: 1.7rem;
    }
    h4 .dark-special-border {
        border-bottom: 5px solid $dark;
    }
    h4 .primary-special-border {
        border-bottom: 5px solid $primary;
    }
    

    h5 {
        font-size: 1.3rem;
    }
    h6,
    .jobDescription ul li {
        font-size: 1rem;
    }
    .projectName {
        font-size: 40px;
        line-height: 64px;
    }
    h6.projectDescription {
        line-height: 34px;
        font-size: 18px;
    }
    h6.projectType {
        font-size: 1rem;
    }
    .landingPage {
        .mainTitleColumns {
            min-height: 65vh;
            img {
                max-width: unset;
            }
        }
        
        .mainTitle {
            display: flex;
            flex-direction: row;
        
            .border {
                border-left: 13px solid black;
                margin: 22px 18px 60px 0;
            }
        }
    }
    .centeredUnderscore-dark {
        width: 33%;
        border-bottom: 9px solid $dark;
    }

    // .reversedRowImageMargin {
    //     margin-top: -9.5rem;
    // }
}

.mb-2{
    margin-bottom: unset !important;
}

.is-size-5{
font-size: 16px !important;
margin-top: 6px;
}

.projectFilter, .technologyFilter, .projectTechnologyScrolledFilter{
    align-items: unset !important;
}
.gameBadges{
margin-top: 40px;}

.is-primary-link {
    background-color: $primary;
    font-family: 'SegoeUIBold';
    cursor: pointer;
    padding: 10px 20px;
    font-weight: 900;
    line-height: 34px;
    text-align: center;
    color: white;
    transition: color .2s ease-in;
}
.field{
    align-self: unset !important;
    margin-top: 5px;
}
.projectType .T{
}
.is-primary-link:hover {
    background-color: black;
    color: white;
    
}
.navbar-burger{
    display: flex;
}
.navbar .navbar-end {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: $desktop) {
        flex-direction: column;
    }
}

@media (max-width: $desktop) {
    #navbarMenuHeroB {
        position: absolute;
        width: 100%;
    }
}

.navbar .navbar-end .is-primary-link.contact_us {
    background-color: $primary;
    color: white;   
}
.navbar .navbar-end .is-primary-link.contact_us.is-active {
    background-color: white;
    color: black;
    border: 2px solid #000000;   
}

.navbar .navbar-end .navbar-item {
    background-color: white;
    margin: 0 2rem;
    height: fit-content;
    border-bottom: 5px solid white;
    margin-top: 5px;   
}
.pb-0{
    font-size: 16px !important;
}
.pb-1{
    font-size: 16px !important;
    font-weight: 700 !important;
    color:#00D5FF !important;
}
.has-text-weight-normal{
    color:#fff;
}
.has-text-weight-normal:hover{
    color:#fff;
}
@media (max-width: $desktop) {
    .navbar .navbar-end .navbar-item {
        margin: 14px 0;
    }
}



.navbar .navbar-end .navbar-item.is-active {
    font-weight: 700;
    border-bottom: 5px solid $primary;
}

// Content
.team-description{
    max-width: 700px;
    margin: auto;
    text-align: center !important;
}
.camelCodingContent {
    .ourWork {
        background-color: $primary;
    }
    .theTeam {
        background-color: $dark;
    }
    .testimonials {
        background-color: $light;
    }
    .findUs,
    .content-vacancy {
        background-color: $primary;
    }
    .square {
        position: relative;
        .quotationMark {
            position: absolute;
            left: 3%;
            top: 3%;
            width: 20%;
            z-index: 0;
        }
        .vacancyContent {
            position: absolute;
            right: 3%;
            top: 3%;
            width: 20%;
        }
        .line{
            width: 19px;
            border-bottom: 5px solid $dark;
            height: 44%;
        
        }
        .position {
            line-height: 13px;
        }
    }
      
    .square:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }
      
    .content-text {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    @media (max-width: 768px) {
        .findUs .square {
            .content-text {
                position: relative;
            }
        }
        .findUs .square::after {
            padding-bottom: unset;
        }
    }
    
}
.is-black:hover{
    background-color: #FFF !important;
    color: #00001E !important;
}
.has-background-dark{
    color: #FFF !important;
}
.has-background-dark:hover{
background-color: #00D5FF !important;
color: #FFF !important;
}
.is-white-button-link {
    padding: 6px 21px;
    background-color: white;
    color: $dark;
}

.white-dark-link {
    background-color: white;
    padding: 5px 6%;
    color:#00001E;
}
.white-dark-link:hover {
    background-color: #00001E;
    color: #F7F7F7 !important;
    padding: 5px 6%;
}

.findUsFooter .is-primary-link.reverted {
    color: $dark!important;
    font-weight: 900 !important;

}
.findUsFooter .is-primary-link.reverted:hover {
    background-color: white;

}

.findUsFooter .is-white-link.reverted {
    background: white;
    color: $dark!important;
}
.findUsFooter .is-white-link.reverted:hover {
    background-color: $dark;
    color: white!important;
}

//FindUs Footer 
.findUsFooter.is-primary {
    .hero-body {
        color: white;
    }
    h4 {
        color: white;
    }
}

.findUsFooter.is-dark {
    h4 {
        color: $primary;
    }
}

//Footer
footer{
    padding: 24px 0px !important;
}
footer.is-primary {
    background-color: $dark;
    span {
        color: $primary;
    }

}
footer.is-dark {
    background-color: $dark;
    span {
        color: $primary;
    }
}

//team
.teamTitle{
    margin-bottom: 38px !important;
}
.teamPosition{
font-size: 14px !important;
}
.teamName{
    font-size: 20px;
}
.centeredUnderscore-primary {
    width: 16%;
    border-bottom: 5px solid #00D5FF;
    margin: 0 auto;
    margin-top: 4px;
    margin-bottom: 6px;
}
.vacancyCard {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.image{
    width:100%;
}
.teamCard {
    figure, img {
        max-width: 201px;
        max-height: 204.99px;
    }
    img{
        filter: grayscale(100%);
        transition: .2s ease-in-out;  
        @media (max-width: 768px) {
            filter: grayscale(0);
        }
    }
} 
.teamCard img:hover,
.teamCard img.in-viewport {
    filter: grayscale(0%);
}

.openedVacancyContent {
    width: 40%;
    .is-relative {
        top: 2%;
   
    }
    .teamPageVacancyBaseImage{
        max-width: 330px;
        width: 71%;
    }

    .teamPageVacancyProgLanguageImage {
        position: absolute;
        width: 46%;
        left: 50%;
        top: 47%;
        transform: translate(-50%, -50%);
    }
}
@media (max-width: 527px) {
    .openedVacancyContent {
        width: 100%;
        margin-bottom: 1rem;
    }
}


// Contact
input, textarea{
    font-weight: 500 !important;
    color: #FFF !important;
    font-size: 16px !important;

}
input.errorBorder,
textarea.errorBorder {
    border-bottom: 2px solid red;
}

.contact.hero{
    position: relative;
}

input:focus::placeholder, textarea:focus::placeholder {
    color: transparent;
  }
input:focus-visible, textarea:focus-visible{
    outline: unset !important;
}
.mountain {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 33%;
}
.moon {
    position: absolute;
    right: 6%;
    bottom: 37%;
    width: 5%;
}
.camelContact{
    position: absolute;
    left: 12%;
    bottom: 0;
    width: 14%;
}

.popupText {
    position: absolute;
    top: 3%;
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 20px;
    border-radius: 10001px;
    color: white;
    font-weight: 600;
    opacity: 0;
    transition: all .8s ease-in;

    &.success {
        background-color: lightgreen;
    }
    &.error {
        background-color: red;
    }
    &.show {
        opacity: 1;
    }
}

.grecaptcha-badge { 
    visibility: hidden;
}


//Our work

.ourWorkProjects .hero .hero-body {
    @media (min-width: 768px) {
        padding: 8.2rem 3rem;
    }
    @media screen and (min-width: 0) and (max-width: 1023px) {
        .columns {
            display: block;
            .column {
                width: 100%;
            }
        }
     }
    
}


.ourWorkProjects .hero.project .hero-body {
    .columns {
        .projectImage {
            padding-right: 2rem;
            padding-left: 0;
            img {
                height: 528px; 
                width: auto; 
                margin: 0 auto; 
                object-fit: cover;
                @media (max-width: 768px) {
                    height: unset;
                    max-height: 528px;
                    min-height: 300px;
                }
            }
        }
        .projectDetails {
            padding-left: 2rem;
            padding-right: 0;
        }
        @media screen and (min-width: 0) and (max-width: 1023px) {
            .projectImage {
                padding-right: 0;
                padding-left: 0;
            }
            .projectDetails {
                padding-left: 0;
                padding-right: 0;
            }
            .projectType {
                margin-top: 1rem;
            }
        }
    }
}

.ourWorkProjects .hero.projectReversed .hero-body {
    background-color: #F7F7F7;
    .columns {
        flex-direction: row-reverse;
        display: flex;
        .projectImage {
            padding-left: 2rem;
            padding-right: 0;
            img {
                height: 528px; 
                width: auto; 
                margin: 0 auto; 
                object-fit: cover;
                @media (max-width: 768px) {
                    height: unset;
                    max-height: 528px
                }
            }
        }
        .projectDetails {
            padding-right: 2rem;
            padding-left: 0;
        }  
       
    }
    @media screen and (min-width: 0) and (max-width: 1023px) {
        .columns {
            display: block;
            .projectImage {
                padding-left: 0;
                padding-right: 0;
            }
            .projectDetails {
                padding-right: 0;
                padding-left: 0;
            }
            .projectType {
                margin-top: 1rem;
            }
        }  
    }      
}
// .projectType label:hover{
//     background-color: #F7F7F7;
//     color:#00D5FF;
//     font-weight: 800;
// }
.hero.project.collection_card {
    background-color: #D8E4E7;

    
}
.hero.project.collection_card.collection_image img {
    height: 271px;
    width: auto; 
    margin: 0 auto; 
    object-fit: cover; 
    min-height: 129px;
    @media (max-width: 769px) {
        height: unset;
    }
}


.projectTypeContainer,
.technologiesContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .technology {
        min-height: 45px; margin: 3px 0px;
    }
    .projectType {
        min-height: 45px; margin: 6px 0px;
    }
}

.scrolledFilterDiv  {
     .technology {
        min-height: 25px;
    }
}

.project-type-link, 
.technology-type-link {
    border: 1px solid #fffffff0;
    border-radius: 44px;
    cursor: pointer;
    white-space: nowrap;
}
@media (max-width: 768px) {
    .project-type-link, 
    .technology-type-link {
        padding: 6px 6px;
    }
}

//Our work - Scrolledfilter
.has-margin-top-6-desktop{
    margin: auto;
}
.projectTypeScrolledFilter{
    max-height: 85px;
}
.hiddenByProjectType.hiddenByTechnology {
    display: none;
}
.ourWork {
    background-color: $primary;
}
.scrolledFilter {
    background-color: $primary;
    transition: all .02s ease-in;
    width: 100%;
    z-index: 2;

    .technologyFilter {
        justify-content: flex-end;
    }
}

.technologyFilter{
    a {
        background-color: white;
        color: $dark;
    }
    select {
        background: $primary;
        border: 1px solid white;
        color:#ffffffcc;
        border-radius: 50px;
    }
    .select:not(.is-multiple):not(.is-loading)::after {
        border-color: white;
    }
    .select select:active,
    .select select:focus {
        border-color: unset;
        box-shadow: unset;
    }

    
    input + label{
       display: none; 
    }
    input.active + label{
        display: block; 
    }
}
.project-type-link, 
.technology-type-link {
    background-color: $primary;
    color: #fffffff0;
    transition: all .2s ease-in;
}
.technology-type-link:hover, .project-type-link:hover {
    background-color: white;
    color: #00001E;
}
.projectType input.active + label,
.technology input.active + label,
.project-type-link.active, 
.technology-type-link.active {
    background-color: white;
    color: $dark;
    transition: all .2s ease-in;
}

.project-type-link, 
.technology-type-link {
    background-color: transparent;
}


.is-transparent {
    opacity: 0;
}
.our-work-primary-special-border{
    margin-top: 5px;
    border-bottom: 4px solid $primary;
    width: 35px;
}

@media (max-width: 768px) {
    .scrolledFilter {    
        .technologyFilter {
            justify-content: flex-start;

           
        }
        select {
            height: 36px;
            font-size: .75rem;
        }
    }
}

@media (max-width: 1407px) {
    .scrolledFilter {    
        .technologyFilter {
           
        }
        select {
            height: 36px;
            font-size: .75rem;
        }
    }
}

@media (min-width: 1408px) {
    .scrolledFilter {    
        .technologyFilter {
           
        }
        select {
            height: 36px;
            font-size: .8rem;
        }
    }
}


//Job description
.jobDescription {
    .logos {
        position: relative;
        width: fit-content;
        margin: 0 auto;

        .vacancyLogo{
            position: absolute;
            bottom: 21px;
            right: 12px;
            width: 13%;
        }
    }
    ul li {
        list-style: inside;
    }

}
@media (max-width: 458px) {
    .jobDescription {
        .logos {    
            .vacancyLogo{
                bottom: 19px;
                right: 10px;
                width: 13%;
            }
        }
    }
}

@media (max-width: 391px) {
    .jobDescription {
        .logos {    
            .vacancyLogo{
                bottom: 16px;
                right: 8px;
                width: 13%;
            }
        }
    }
}



//lightbox gallery

  .modal-column {
    padding: 0 8px;
  }
  
  .modal-row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .modal-column {
    float: left;
    width: 20%;
  }
  
  /* The Modal (background) */
  .custom-modal {
    // display: none;
    // display: block;
    opacity: 0;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 30, 0.7);
    z-index: -1;
    transition: opacity .2s ease-in;
  }
  
  /* Modal Content */
  .modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 50px;
    width: 90%;
    max-width: 1200px;
    text-align: center;
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
  }

  /* Modal title */
  .modal-title {
    text-align: center;
    margin-bottom: 40px;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  /* The Close Button */
  .modall-close {
    color: black;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
    cursor: pointer;
  }
  .modal-slides {
      padding-bottom: 15px;
  }

  .modal-slides, .modal-thumbnailSlides {
      width: 81%;
      margin: 0 auto;
      position: relative;
  }
  .modal-thumbnailSlides {
    display: flex;
    justify-content: center;
  }
  
  .modal-close:hover,
  .modal-close:focus {
    color: #999;
    text-decoration: none;
    cursor: pointer;
  }
  
  .modal-mySlides {
    display: none;
    padding: 0 8px;
    display: block;
    position: absolute;
    top: 0;
    transition: opacity .5s ease;
  }
  .modal-mySlides:nth-child(1) {
      position: relative;
  }
  

  .modal-mySlides img {
      width: 100%;
  }
  
  .modal-cursor {
    cursor: pointer;
  }
  
  /* Next & previous buttons */
  .modal-prev,
  .modal-next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: #00D5FF;
    font-weight: bold;
    font-size: 20px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
  }
  .modal-prev {
      left: 5%;
      border-radius: 3px 0 0 3px;
  }
  
  /* Position the "next button" to the right */
  .modal-next {
    right: 5%;
    border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .modal-prev:hover,
  .modal-next:hover {
    color: #00001E;
  }
  
  .custom-modal img {
    margin-bottom: -4px;
  }
  
  .caption-container {
    text-align: center;
    background-color: black;
    padding: 2px 16px;
    color: white;
  }
  .projectSubtitle{
    font-size: 18px !important;
  }
  .modal-demo {
    opacity: 0.5;
    transition: opacity .2s ease-in-out;
  }
  
//   .active,
  .modal-demo:hover {
    opacity: 1 !important;
    transition: opacity .5s ease-in-out;
  }
  
  img.hover-shadow {
    transition: 0.3s;
  }
  
  .hover-shadow:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  @media(max-width: 768px) {
      .custom-modal {
          width: 100vw;
      }
      .modall-close {
        top: 0px;
        right: 10px;
        font-size: 25px;
      }
      .modal-content {
          padding: 30px 0;
          width: 100%;
      }
      .modal-slides {
          width: 100%;
      }
      .modal-thumbnailSlides {
          display: none;
      }
      .modal-prev, .modal-next {
          top: 70%
      }
      .modal-prev {
          left: 0;
      }
      .modal-next {
          right: 0;
      }
  }

//Filter modal 
.filterModalButton{
    color: #00001E !important;
}
.filterModalButton:hover{
    color: #fff !important;
    background-color: #00001E !important;
}
#filterModal {
    .modal-card.filter {
        text-align: center;
        display: flex;
        justify-content: space-between;
        height: 100%;
    }
}

.modal-close {
    webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background-color: rgba(10, 10, 10, 0.2);
      border: none;
      border-radius: 290486px;
      cursor: pointer;
      display: inline-block;
      font-size: 1rem;
      height: 30px;
      outline: none;
      position: relative;
      vertical-align: top;
      width: 30px;
      background: none;
      height: 60px;
      position: fixed;
      right: 20px;
      top: 20px;
      width: 60px;
      max-height: 33px;
      max-width: 33px;
}








