@charset "utf-8";



$navbar-height: 5rem;
$primary: #00D5FF;
$dark: #00001E;
$light: #F8F8F8;
$success: #D8E4E7;
$navbar-background-color: white;
$navbar-item-hover-background-color: white;
$navbar-item-hover-color: red;
$navbar-item-img-max-height: 3rem;
$footer-padding: 1rem 1.5rem;
$control-radius: 0;







//base
@import "../node_modules/bulma/sass/base/_all.sass";
// @import "../node_modules/bulma/sass/base/animations.sass";
// @import "../node_modules/bulma/sass/base/generic.sass";
// @import "../node_modules/bulma/sass/base/helpers.sass";
// @import "../node_modules/bulma/sass/base/minireset.sass";

//components
// @import "../node_modules/bulma/sass/components/_all.sass";
@import "../node_modules/bulma/sass/components/breadcrumb.sass";
@import "../node_modules/bulma/sass/components/dropdown.sass";
@import "../node_modules/bulma/sass/components/modal.sass";
@import "../node_modules/bulma/sass/components/navbar.sass";

//elements
// @import "../node_modules/bulma/sass/elements/_all.sass";
@import "../node_modules/bulma/sass/elements/button.sass";
@import "../node_modules/bulma/sass/elements/container.sass";
@import "../node_modules/bulma/sass/elements/image.sass";

//form
@import "../node_modules/bulma/sass/form/_all.sass";
@import "../node_modules/bulma/sass/form/checkbox-radio.sass";
@import "../node_modules/bulma/sass/form/file.sass";
@import "../node_modules/bulma/sass/form/input-textarea.sass";
@import "../node_modules/bulma/sass/form/select.sass";
@import "../node_modules/bulma/sass/form/shared.sass";
@import "../node_modules/bulma/sass/form/tools.sass";

//grid
// @import "../node_modules/bulma/sass/grid/_all.sass";
@import "../node_modules/bulma/sass/grid/columns.sass";
// @import "../node_modules/bulma/sass/grid/tiles.sass";

//helpers
@import "../node_modules/bulma/sass/helpers/_all.sass";

//layout
@import "../node_modules/bulma/sass/layout/_all.sass";

//utilities
@import "../node_modules/bulma/sass/utilities/_all.sass";
// @import './node_modules/bulma/sass/utilities/extends.sass';
// @import './node_modules/bulma/sass/utilities/initial-variables.sass'; // required 
// @import './node_modules/bulma/sass/utilities/derived-variables.sass'; // required 
// @import './node_modules/bulma/sass/utilities/mixins'; // required 

//whole bulma
// @import "../node_modules/bulma/bulma.sass";

//spacing helper
@import "../node_modules/bulma-helper-spacing/spacing";

//my sytle
@import "./myStyle.scss";